var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.loading
      ? _c(
          "div",
          [
            !_vm.showConfirmaton
              ? _c(
                  "div",
                  [
                    _c("div", { staticClass: "grid grid-cols-2" }, [
                      _c(
                        "div",
                        { staticClass: "flex justify-between" },
                        [
                          _c("BackNav", {
                            on: {
                              click: function ($event) {
                                return _vm.$emit("back")
                              },
                            },
                          }),
                          _c("p", { staticClass: "text-lightGrey-2 text-sm" }, [
                            _vm._v("1 of 2"),
                          ]),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "h5",
                      {
                        staticClass: "md:text-2xl text-xl font-bold text-dark",
                      },
                      [_vm._v("Request bank statement")]
                    ),
                    _c("p", { staticClass: "text-grey md:text-lg" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.accountPending
                              ? "Here are the details you entered the last time"
                              : "Please, input the details of your most active bank account."
                          ) +
                          " "
                      ),
                    ]),
                    _c("InlineToast", {
                      staticClass: "mt-2",
                      attrs: {
                        display: _vm.showError,
                        type: "error",
                        description: _vm.flaggedReason,
                      },
                    }),
                    _c(
                      "form",
                      { staticClass: "mt-6", attrs: { action: "" } },
                      [
                        _c("Input", {
                          attrs: {
                            placeholder: "Enter account number",
                            label: "Account number",
                            width: "w-full",
                            errorText: "Account number incomplete",
                            validation: _vm.rules.account,
                            id: "account number",
                            disabled: _vm.accountPending,
                            type: "number",
                            required: "",
                          },
                          model: {
                            value: _vm.userData.accountNumber,
                            callback: function ($$v) {
                              _vm.$set(_vm.userData, "accountNumber", $$v)
                            },
                            expression: "userData.accountNumber",
                          },
                        }),
                        _c("Select", {
                          staticClass: "mt-6",
                          attrs: {
                            formLabel: "Bank Name",
                            placeholder: "Select bank",
                            label: "prettyName",
                            options: _vm.allBanks,
                            reduce: function (bank) {
                              return bank.id
                            },
                            validation: _vm.rules.bankId,
                            disabled: _vm.accountPending,
                            search: "",
                            required: "",
                          },
                          model: {
                            value: _vm.userData.bankId,
                            callback: function ($$v) {
                              _vm.$set(_vm.userData, "bankId", $$v)
                            },
                            expression: "userData.bankId",
                          },
                        }),
                        _c("TelInput", {
                          staticClass: "mt-4",
                          attrs: {
                            id: "phone",
                            label: "Enter registered phone no",
                            width: "w-full",
                            placeholder: "Phone no registered with bank",
                            errorText: "Phone number is invalid",
                          },
                          on: { valid: _vm.isPhoneValid },
                          model: {
                            value: _vm.userData.phone,
                            callback: function ($$v) {
                              _vm.$set(_vm.userData, "phone", $$v)
                            },
                            expression: "userData.phone",
                          },
                        }),
                        _c("InlineToast", {
                          staticClass: "mt-10",
                          attrs: {
                            display: true,
                            custom: "",
                            type: "info",
                            description:
                              "Your bank will charge you N40 per page of your statement.",
                          },
                        }),
                        _c("Button", {
                          staticClass: "mt-2",
                          attrs: {
                            width: "w-full",
                            text: _vm.accountPending
                              ? "Look's good"
                              : "Continue",
                            disabled: _vm.validateButton,
                            loading: _vm.initiateLoading,
                          },
                          on: { click: _vm.initiateStatementRequest },
                        }),
                        _vm.accountPending
                          ? _c(
                              "p",
                              {
                                staticClass:
                                  "my-6 font-semibold text-brandPurple text-center cursor-pointer",
                                on: {
                                  click: function ($event) {
                                    _vm.setEdit = true
                                  },
                                },
                              },
                              [_vm._v(" Edit bank account details ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _c("ConfirmationPage", {
                  attrs: {
                    phone: _vm.userData.phone,
                    id: _vm.accountId,
                    accountData: _vm.userData,
                    selectedBank: _vm.selectedBank,
                  },
                  on: {
                    back: _vm.handleBack,
                    close: function ($event) {
                      return _vm.$emit("close")
                    },
                    success: function ($event) {
                      return _vm.$emit("success")
                    },
                  },
                }),
            _c(
              "Modal",
              {
                attrs: {
                  display: _vm.displayValidationStatus,
                  hideClose: "",
                  size: "large",
                },
              },
              [
                _c("VerificationStatus", {
                  attrs: { type: "requestPdf", queryResult: _vm.queryResult },
                  on: {
                    back: _vm.handleBack,
                    close: function ($event) {
                      return _vm.$emit("close")
                    },
                    success: function ($event) {
                      return _vm.$emit("success")
                    },
                    connectBank: function ($event) {
                      return _vm.$emit("back")
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }